* {
	margin: 0;
	border: 0;
	box-sizing: border-box;
}

body {
	margin: 0;
	font-family: -apple-system, 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

//main.jsx


header.header {
	min-height: 160px;
	background-color: #1a97fe;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: white;

	h1 {
		font-family: Arial, Helvetica, sans-serif;
		font-size: 50px;
		font-weight: 500;
		margin: 0px 50px;
	}

	.input-section {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		font-weight: 600;
		margin: 0px 35px;

		input[type="file"] {
			display: none;
		}
		.button-container{
			display: flex;
			.custom-button {
				margin: 5px 0px;
				display: inline-block;
				background-color: white;
				border-radius: 10px;
				color: #1a97fe;
				padding: 10px 15px;
				cursor: pointer;
				transition: all 0.3s;
			}
			
			.custom-button:hover{
				box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
				background-color: whitesmoke;
			}

			.clear-data{
				margin-left: 15px;
			}
		}

		.select-section {
			display: flex;
			flex-direction: column;
			font-size: 15px;
			font-weight: 600;

			select {
				font-weight: 600;
				color: #1a97fe;
				padding: 5px;
				border-radius: 5px;
				cursor: pointer;
				margin: 5px 0px;
			}
		}
	}
}

//MainLanguage.jsx
main {
	background-color: whitesmoke;

	.container-value {
		padding: 15px;
		display: flex;
		overflow: scroll;
		.keys {
			min-width: 500px;
			background-color: white;
			box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
			border-radius: 20px;
			margin: 15px;
			height: calc(100vh - 280px);
			min-height: 500px;
			overflow: scroll;

			.title {
				height: 100px;
				border-radius: 10px;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: #fff;
				position: sticky;
				top: 0;
				h3{
					font-weight: 500;
					font-size: 20px;
				}
				.uppercase{
					text-transform: uppercase;
				}
			}
			.title.keysTab{
				flex-direction: column;
			}

			.addKeyBox{
				width: 100%;
				padding: 0px 15px;
				height: 30px;
				margin-top: 10px;
				input.addKey{
					border: 1px solid grey;
					padding: 0px 8px;
					border-radius: 8px;
					width: 70%;
					height: 30px;
					font-size: 15px;
				}
				button.addKey{
					height: 30px;
				}
			}
			.content-box {
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: flex-end;
				.row {
					width: 100%;
					overflow: scroll;
					padding: 5px 15px;

					div {
						height: 30px;
						display: flex;
						border-top: 1px solid lightgrey;
						align-items: center;
						input {
							color: black;
							width: 100%;
							padding: 2px;
							font-size: 15px;
							height: 25px;
						}

						input.error {
							border-radius: 5px;
							border: 1px solid rgb(255, 63, 63);
						}

						svg {
							color: tomato;
							font-size: 20px;
							cursor: pointer;
							transition: all 0.2s;
							padding: 5px;
						}

						svg:hover {
							color: red;
						}

						h3 {
							overflow-y: scroll;
							white-space: nowrap;
							width: calc(100% - 20px);
							margin: 5px 0px;
							font-weight: normal;
							font-size: 15px;
						}

						h3::-webkit-scrollbar {
							display: none;
						}

						/* Hide scrollbar for IE, Edge and Firefox */
						h3 {
							-ms-overflow-style: none;
							/* IE and Edge */
							scrollbar-width: none;
							/* Firefox */
						}
					}
					div:first-child{
						border: none;
					}
				}

				p {
					width: 100%;
					display: table;
					white-space: nowrap;
				}
			}
		}
	}

	.bottom-section {
		text-align: center;
		height: 60px;

	}
	button {
		background-color: #1a97fe;
		color: white;
		padding: 10px 15px;
		border-radius: 10px;
		font-weight: 600;
		margin: 10px 5px;
		transition: all 0.3s;
		cursor: pointer;
	}

	button.saved{
		background-color: #56b3ff;
	}

	button:hover {
		background-color: #368ace;
	}

	button.addKey{
		padding: 5px 10px;
		font-size: 15px;
		width: calc(30% - 10px);
		margin: 0px 5px;
	}
}